<template>
    <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create New Interview</h1>
            <hr>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                    <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <form @submit.prevent="postInterview" class="row p-2">
                            <div class="col-xl-6 form-group mb-3">
                                <label for="">Topic</label>
                                <input v-model="form.topic" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                            </div>
                            <div class="col-xl-6 form-group mb-3">
                                <label for="">Date &amp; Time</label>
                                <input v-model="form.start_time" type="datetime-local" required class="form-control text-grey-900 font-xsss fw-600">                        
                            </div>
                            <div class="col-xl-12 form-group mb-3">
                                <label for="">Agenda</label>
                                <textarea v-model="form.agenda" class="form-control text-grey-900 font-xsss fw-600"></textarea>
                            </div>
                            <div class="col-xl-12 form-group mb-3">
                                <label for="">Participant</label>
                                <select @change="setParticipant($event)" class="form-control">
                                  <option value="" disabled selected>Choose Participant</option>
                                  <option v-for="(item, index) in students" :key="index" :value="item.id">{{item.name}}</option>
                                </select>
                            </div>
                            <div v-if="participant.length" class="col-12 text-center py-3">
                              <div class="table-responsive-xl">
                                  <datatable :class="'table table-hover table-bordered'" :columns="columns" :data="participant" :page="1" :perPage="10">
                                      <template name="default" slot-scope="{ row, index }">
                                          <tr>
                                              <td>{{ index+1 }}</td>
                                              <td>{{ row.name }}</td>
                                              <td>{{ row.email }}</td>
                                              <td>
                                                  <a href="javascript:void(0)" @click="deleteParticipant(index)"><i class="fas text-danger fa-times"></i></a>
                                              </td>
                                          </tr>
                                      </template>
                                      <template name="no-result">
                                          Nothing to see here
                                      </template>
                                  </datatable>
                              </div>
                              <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                            </div>
                            <div class="col-xl-12 form-group text-right my-4">
                                <router-link :to="{name: 'ReflectionCoursesAdmin', params: {idCourse: paramsId}, hash: '#interview'}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                <input type="Submit" class="btn btn-warning" value="Submit">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import axios from 'axios'

export default {
    name: "ConferenceCreate",
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            participant: [],
            students: [],
            id_participant: [],
            form: {
                topic: '',
                start_time: '',
                agenda: ''
            },
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail wide-column'},
                {label: 'Name', field: 'name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail wide-column'},
                {label: 'Email', field: 'email', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail wide-column'},
                {label: 'Action', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail wide-column'},
            ],
            page: 1
        }
    },
    created(){
      this.getStudent();
    },
    methods:{
        setParticipant(e) {
          this.id_participant.push(e.target.value)
          this.getStudent();
          e.target.value = ""
        },
        deleteParticipant(id){
          this.id_participant.splice(id, 1);
          this.getStudent();
        },
        async getStudent() {
            var data = {
                participant : this.id_participant,
                slug : this.paramsId
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/reflection/interview/get-student`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
              this.students = res.data.data
              this.participant = res.data.participant
            }).catch((err) => {
              console.log(err)
            })
        },
        async postInterview() {
            var data = {
                topic : this.form.topic,
                start_time : this.form.start_time,
                agenda : this.form.agenda,
                course_id : this.paramsId,
                participant: this.id_participant
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/reflection/interview`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Interview Guide!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'ReflectionCoursesAdmin', params: {idCourse: this.paramsId}, hash: '#interview' })
            }).catch((err) => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Interview Guide!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    }
}
</script>
